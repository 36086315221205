<template>
  <div class="main" :style="mainHeight">
    <div class="tab_header">
        <div v-for="(item, index) in monthList" :key="index" :class="tab_active == index ? 'balance tab_active' : 'balance' " @click="setTab(item ,index)" >
          {{item.year}}-{{item.month}}
        </div>
    </div>
    <div class="list_box">
      <van-list v-model="loading" :finished="finished" class="content_box" v-if="lists.length">
        <van-cell>
          <span>日期</span>
          <span>用能</span>
          <span>金额</span>
        </van-cell>
        <van-cell v-for="(item, index) in lists" :key="index">
          <span>{{item.dataDate}}</span>
          <span>{{item.readings}}</span>
          <span>{{item.amount}}元</span>
        </van-cell>
      </van-list>
      <p v-else>----该月份暂无充值记录----</p>
    </div>
  </div>
</template>

<script>
  import { userDetail } from '@/api/home'
  import api from '@/api/index'
  const { getRechargesInfor, fetchData } = api
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { getWxRecharge } from '@/api/wxSdk'
  import { userDB } from '@/db'
  export default {
    data() {
      return {
        userName: '',
        mainHeight: `height:${window.innerHeight || document.documentElement.clientHeight}px;overflow:hidden`,
        userRemainder: '',
        companyName: '',
        tab_active: 0,
        pageCount: 0,
        loading: false,
        finished: true,
        showActive: -1,
        lists: [],
        monthList: [],
        currentList: [],
        meterId: ''
      }
    },
    computed: {
      ...mapState(['user'])
    },
    filters: {
      clearTime(val) {
        if ((!!val) && (val.indexOf('T'))) {
          return val.split('T')[0]
        }
        return val
      },
      getMonth(val) {
        if (val.indexOf('-')) {
          return `${val.split('-')[1]} - ${val.split('-')[2]} `
        } else {
          return val
        }
      }
    },
    created() {
      this.meterId = this.$route.query.id

      const data = new Date()
      for (let i = 0; i < 6; i++) {
        const obj = { 'year': data.getFullYear(), 'month': 0 }
        if ((data.getMonth() + 1) - i) {
          obj.month = (data.getMonth() + 1) - i
        } else {
          obj.month = 12 - (i - (data.getMonth() + 1))
          obj.year = data.getFullYear() - 1
        }
        this.monthList.push(obj)
      }

      const date = `${data.getFullYear()}-${this.formatDays(data.getMonth() + 1)}-${this.formatDays(data.getDate())}`
      this.gettlementDetails(`/running/fees/data/meter/${this.meterId}/monthData/${date}`)
    },
    methods: {
      setTab(item, index) {
        this.tab_active = index
        const date = `${item.year}-${this.formatDays(item.month)}-01`
        this.gettlementDetails(`/running/fees/data/meter/${this.meterId}/monthData/${date}`)
      },
      formatDays(val) {
        if (parseInt(val) < 10) {
          return `0${val}`
        } else {
          return val
        }
      },
      /*
        接口获取结算明细数据
        @parmas url(接口地址) index(改变的数据索引)
      */
      gettlementDetails(url) {
        // 调取接口函数
        fetchData({}, url, 'get').then(res => {
          this.lists = res.list
        }).catch(err => {
          console.error('err', err)
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .main {
    background: #fff;
  }
  .tab_header {
    width:20%;
    float: left;
    font-size: 1rem;
    height: 100%;
    overflow: auto;
    background: #f7f7f7;
    div {
      text-align: center;
      height: 60px;
      line-height: 60px;
      color: #333;
      background: #f7f7f7;
      width: 100%;
      float: left;
      padding: 0.7rem 0;
      font-size: 1.1rem;
    }
    .tab_active {
      color: red;
      background: #fff;
    }
  }

  .list_box {
    height: calc(100% - 100px);
    overflow: hidden;
    p {
      text-align: center;
    }
  }
  .content_box {
    width:98%;
    margin: 0 1%;
    margin-top: 10px;
    height: 100%;
    overflow: auto;
    background: #fff;
    .top {
      border-left: 8px solid #4fb4e9;
      padding-left: 30px;
    }
    span:nth-child(1) {
      width: 40%;
    }
    span {
      display: inline-block;
      width: 30%;
      float: left;
    }
  }
</style>
